import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle, ButtonStyleWithWidth } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./BRegistro.scss";

function BRegistro() {
  const history = useHistory();

  const NextOptions1 = () => {
    history.push("./CGenialIdentificacion" + history.location.search);
  };
  const NextOptions2 = () => {
    history.push("./DVamosIdentificar" + history.location.search);
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title2">
          Registrate <br />y crea tu identidad <br />
          digital
        </p>
      </div>

      <div style={{ marginTop: "4em" }}>
        <ButtonStyleWithWidth
          text="REGISTRARME"
          onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyleWithWidth
          text="INGRESAR"
          onClick={() => NextOptions2()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default BRegistro;

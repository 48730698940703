import React, { useEffect, useRef, useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./DVamosIdentificar.scss";

function DVamosIdentificar(props) {
  const history = useHistory();
  const timer = useRef();

  useEffect(() => {
    timer.current = setInterval(() => {
      NextOptions();
    }, 5000);
  }, []);

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );
  const NextOptions = () => {
    props.redirect();
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "40vh" }}
      >
        <p className="title2" style={{ fontWeight: "bold" }}>
          Verificaremos <br /> tu identidad <br /> antes de comenzar{" "}
        </p>
      </div>
    </div>
  );
}
export default DVamosIdentificar;

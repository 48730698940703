import React, { useEffect, useRef, useState } from "react";
import logo from "./../../assets/logoins.png";
import logoAnteia from "./../../assets/icon_welcome_anteia.svg";
import { ReactComponent as Welcome } from "./../../assets/icon_welcome.svg";

import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./ABienvenido.scss";

function ABienvenido() {
  const history = useHistory();

  const NextOptions = () => {
    history.push("./BRegistro" + history.location.search);
  };
  const timer = useRef();

  useEffect(() => {
    timer.current = setInterval(() => {
      NextOptions();
    }, 5000);
  }, []);

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh", minHeight: "100vh" }}
      >
        <p className="title1" style={{ fontWeight: "bold" }}>
          ¡ Hola !
        </p>
        <p
          className="title1"
          style={{ fontWeight: "bold", paddingBottom: "30px" }}
        >
          Bienvenid@
        </p>

        <Welcome />

        <p className="title3">
          Queremos que sepas
          <br /> que navegaras <br />
          en una plataforma segura
        </p>
      </div>
      <img
        src={logoAnteia}
        alt="logo anteia"
        style={{
          position: "absolute",

          bottom: "50px",
        }}
      />
    </div>
  );
}
export default ABienvenido;

import React, { useState } from "react";
import "./RIngresaAfiliacion.scss";
import { TextFieldStyleSS } from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";
import { TextFieldStyleSSelect } from "../../utils/TextFieldStyle/TextFieldStyle";

function RIngresaAfiliacion(props) {
  const history = useHistory();
  const [data, setData] = useState("Soltero");

  const Next = () => {
    history.push("./RVerifiquemosAfiliacion" + history.location.search);
  };
  const userChange = (name, value) => {
    let temp = props.data;
    temp[name] = value;
    props.setData(temp);
  };

  return (
    <div className="enterdata-style">
      <p>
        Diligencie los siguientes <br /> datos
      </p>

      <TextFieldStyleSSelect
        data={
          <FormControl variant="outlined">
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "white" }}
              shrink
            >
              Estado Civil
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data}
              style={{ color: "white", borderColor: "white", width: "80vw" }}
              onChange={(e) => {
                setData(e.target.value);
                userChange("estado", e.target.value);
              }}
              input={<OutlinedInput notched label={"Estado Civil"} />}
            >
              <MenuItem value={"Soltero"}>Soltero</MenuItem>
              <MenuItem value={"Casado"}>Casado</MenuItem>
              <MenuItem value={"Divorciado"}>Divorciado</MenuItem>
              <MenuItem value={"Viudo"}>Viudo</MenuItem>
            </Select>
          </FormControl>
        }
      />

      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Actividad Económica"
          onChange={(e) => {
            userChange("actividad", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Ingresos mensuales"
          onChange={(e) => {
            userChange("ingresos", e.target.value);
          }}
        />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="ENVIAR Y FIRMAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default RIngresaAfiliacion;

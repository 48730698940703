import { useEffect, useState } from "react";
import useQuery from "../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import { ButtonStyle } from "../utils/Buttons/Buttons";
import { infoMessage } from "../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../utils/TextFieldStyle/TextFieldStyle";
import {
  showMessageCallback,
  getColorsByExecFlow,
  PathSelectConfig,
  getPathSelectConfig,
  pathSelectCallback,
} from "./hyperFlowApi";
import { useHistory } from "react-router-dom";
import backgroundImage from "../assets/backgrounds/007-SKY.jpg";

const HyperFlowPathSelect = () => {
  let history = useHistory();
  //query params
  const query = useQuery();
  const execFlowId = query.get("execFlowId") ?? "error";
  const code = query.get("code") ?? "error";
  const stepId = query.get("stepId") ?? "error";
  const configId = query.get("configId") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [loading, setLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const [data, setData] = useState<PathSelectConfig | null>(null);

  //get colors
  async function getColors() {
    let resp = await getColorsByExecFlow(execFlowId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
  }

  async function executeCallback(selectedPath: string) {
    let req = {
      code: code,
      execFlowId: execFlowId,
      currentStep: stepId,
      selectedPath: selectedPath,
    };
    await pathSelectCallback(req);
    await delay(2000);
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function next(selectedPath: string) {
    try {
      setLoading(true);
      await executeCallback(selectedPath);
      history.push("/hyperFlow/continueFlow?code=" + code);
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

  async function getConfig() {
    setLoading(true);
    let config = await getPathSelectConfig(configId);
    if (config === null) {
      alert("Error obteniendo datos");
    }
    setData(config);
    setLoading(false);
  }

  useEffect(() => {
    getColors();
    getConfig();
  }, []);

  function renderButtons(): JSX.Element[] {
    let lst: JSX.Element[] = [];
    console.log(data);
    console.log(typeof data?.buttons);
    if (data !== null) {
      for (let text in data.buttons) {
        console.log(text);
        let value = data.buttons[text];
        lst.push(
          <div
            key={text}
            style={{
              marginTop: "20px",
            }}
          >
            <ButtonStyle
              onClick={() => {
                next(value);
              }}
              text={text}
            />
          </div>
        );
      }
    }

    return lst;
  }

  const customStyle = {
    "--fontColorPrimary": darkMode ? "#ffffff" : "#000000",
  } as React.CSSProperties;

  //Render
  return (
    <div className="hfMaster" style={customStyle}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${backgroundImage})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          {loading && (
            <div>
              <h1
                className="txt-label-title-hf"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                Espera un momento
              </h1>
              <ClipLoader
                loading={loading}
                size={150}
                color={darkMode ? "#FFFFFF" : "#000000"}
              />
            </div>
          )}

          {!loading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                className="txt-label2"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                {data?.title}
              </h1>

              <div
                style={{
                  marginTop: "40px",
                }}
              ></div>
              {renderButtons()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperFlowPathSelect;

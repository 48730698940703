import React, { useState } from "react";
import "./KIngresaCuentaAhorros.scss";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";
import {
  TextFieldStyleSS,
  TextFieldStyleSSelect,
} from "../../utils/TextFieldStyle/TextFieldStyle";

function KIngresaCuentaAhorros(props) {
  const history = useHistory();
  const [data, setData] = useState("ASALARIADO");
  const Next = () => {
    history.push("./LVerifiquemosCuentaAhorros" + history.location.search);
  };

  const userChange = (name, value) => {
    let temp = props.data;
    temp[name] = value;
    props.setData(temp);
  };

  return (
    <div className="enterdata-style">
      <p>Ingresa los siguientes datos</p>

      <br />
      <br />

      <TextFieldStyleSSelect
        data={
          <FormControl variant="outlined">
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "white" }}
              shrink
            >
              Tipo de Ingresos
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data}
              style={{ color: "white", borderColor: "white", width: "80vw" }}
              onChange={(e) => {
                setData(e.target.value);
                userChange("tipoingresos", e.target.value);
              }}
              input={<OutlinedInput notched label={"Tipo de Ingresos"} />}
            >
              <MenuItem value={"ASALARIADO"}>ASALARIADO</MenuItem>
              <MenuItem value={"INDEPENDIENTE"}>INDEPENDIENTE</MenuItem>
            </Select>
          </FormControl>
        }
      />

      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Monto de Ingresos"
          onChange={(e) => {
            userChange("monto", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Ocupación o actividad económica"
          onChange={(e) => {
            userChange("ocupacion", e.target.value);
          }}
        />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="SIGUIENTE"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
        <br />
      </div>
    </div>
  );
}
export default KIngresaCuentaAhorros;

import { useEffect, useRef, useState } from "react";
import { Xeia } from "./XEIA";
import { Pantallas, TipoXeia } from "./enums";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  XeiaMessage,
  selectHelpButtonVisible,
  setHelpButtonVisible,
  setXeiaMessage,
} from "../formComponent/formComponentSlicer";

export interface HelpButtonProps {
  tipoPantalla: Pantallas;
  botonAccionClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const HelpButton = (props: HelpButtonProps) => {
  const visible = useAppSelector(selectHelpButtonVisible);
  const [oculto, setOculto] = useState(true);
  const elementsButtonRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  function clickAyuda() {
    setOculto(!oculto);
    if (elementsButtonRef.current) {
      if (oculto) {
        elementsButtonRef.current.classList.remove("hidden");
      } else {
        elementsButtonRef.current.classList.add("hidden");
      }
    }
  }

  function clickCerrar() {
    dispatch(setXeiaMessage(null));
    dispatch(setHelpButtonVisible(true));
  }

  function llamarXeiaChat() {
    const xeiaChat = {} as XeiaMessage;
    xeiaChat.tipoRespuesta = TipoXeia.CHAT;
    setOculto(!oculto);
    dispatch(setHelpButtonVisible(false));
    dispatch(setXeiaMessage(xeiaChat));
  }

  useEffect(() => {
    if (visible) {
      clickCerrar();
    }
  }, [visible]);

  return (
    <>
      <Xeia botonAccionClick={clickCerrar} />
      {visible && (
        <>
          <button
            className="fixed w-[40px] h-[40px] right-4 top-4 z-[60]"
            onClick={clickAyuda}
          >
            <video
              preload="auto"
              className="h-full w-full rounded-full object-cover"
              autoPlay
              playsInline
              muted
              loop
              typeof="video/mp4"
              src="xeia/STANDBY.mp4"
            />
          </button>
          <div className="fixed right-8 top-16 hidden" ref={elementsButtonRef}>
            <div className="flex items-center">
              <p className="text-primary-color font-normal text-sm">
                Chat de Ayuda
              </p>
              <button onClick={llamarXeiaChat}>
                <img
                  className="h-[13.82px] w-[15px] ml-2"
                  src="screen_icons/CHAT_ICON.svg"
                  alt="chat"
                />
              </button>
            </div>
          </div>
        </>
      )}
      {!visible && (
        <button
          className="flex items-center fixed w-[30px] h-[30px] left-4 top-4 z-[100]"
          onClick={clickCerrar}
        >
          <img
            className="h-[21px] w-[17px]"
            src="screen_icons/ATRAS.svg"
            alt="atras"
          />
          <p className="font-semibold text-[12px] ml-2 text-primary-color">
            Regresar
          </p>
        </button>
      )}
    </>
  );
};

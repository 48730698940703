import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { Pantallas, TipoXeia } from "./enums";
import {
  XeiaMessage,
  selectFinalizacionProceso,
  selectRegistrationId,
  selectXeiaMessage,
  sendMessage,
  setHelpButtonVisible,
  setValorPatrimonio,
  setValueDinero,
  setXeiaMessage,
  tryUpdate,
} from "../formComponent/formComponentSlicer";
import { ButtonModal } from "./ButtonModal";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { TextTypeEffect } from "./TextTypeEffect";

export const Xeia = (props: XeiaMessage) => {
  const imagen = useRef<HTMLImageElement>(null);
  const xeia = useAppSelector(selectXeiaMessage);
  const userId = useAppSelector(selectRegistrationId);
  const finalizacion = useAppSelector(selectFinalizacionProceso);
  const mensajesSpace = useRef<HTMLDivElement>(null);
  const [texto, setTexto] = useState<Array<string>>([]);
  const [bienvenidaFinalizo, setBienvenidaFinalizo] = useState(false);
  const [mapMensajes, setMapMensajes] = useState<Array<string>>([]);
  const [currentMapMensajes, setCurrentMapMensajes] = useState(0);
  const [dudaFinalizo, setDudaFinalizo] = useState(false);
  const [mensajes, setMensajes] = useState<Array<Array<string>>>([]);
  const [videoRecomendacionShow, setVideoRecomendacionShow] = useState(false);
  const dispatch = useAppDispatch();

  setTimeout(() => {
    setDudaFinalizo(true);
  }, 4000);
  setTimeout(() => {
    setBienvenidaFinalizo(true);
  }, 8000);

  async function sendMsg(
    event: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  ) {
    if (event.key === "Enter" || event.type === "click") {
      if (texto[1] !== undefined && texto[1].trim() !== "") {
        if (texto[1].includes("no gracias")) {
          setTexto(["user", ""]);
          dispatch(setHelpButtonVisible(true));
        } else {
          if (texto[1].includes("10")) {
            dispatch(setValueDinero(10000000));
          }
          dispatch(sendMessage({ idUsuario: userId, mensaje: texto[1] }));
          setMensajes([...mensajes, texto]);
          setTexto(["user", ""]);
        }
      }
    }
  }

  function alignImage() {
    if (xeia?.imagen) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = document.createElement("img");
      image.src = `data:image/png;base64,${xeia.imagen}`;
      image.onload = () => {
        if (imagen.current && image.width < image.height) {
          canvas.height = image.width;
          canvas.width = image.height;
          if (ctx) {
            ctx.drawImage(
              image,
              canvas.width / 2 - image.width / 2,
              canvas.height / 2 - image.width / 2
            );
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((-90 * Math.PI) / 180);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
          }
          const imgData = canvas.toDataURL("image/png");
          navigator.clipboard.writeText(imgData);
          imagen.current.src = imgData;
        } else {
          if (imagen.current) {
            imagen.current.src = image.src;
          }
        }
      };
    }
  }

  async function libreInversion() {
    dispatch(sendMessage({ idUsuario: userId, mensaje: "3. libre inversion" }));
    setMensajes([...mensajes, ["user", "3. libre inversion"]]);
    setTexto(["user", ""]);
  }

  function dispatchUpdate() {
    dispatch(tryUpdate());
  }

  function si() {
    setMensajes([
      ...mensajes,
      ["user", "Perfecto, el patrimonio ha sido modificado"],
    ]);
    setTexto(["user", ""]);
    dispatch(setValorPatrimonio(700158000));
    setTimeout(() => {
      dispatch(setXeiaMessage(null));
      dispatch(setHelpButtonVisible(true));
    }, 3000);
  }

  useEffect(() => {
    setDudaFinalizo(false);
    alignImage();
    if (xeia?.urlRecomendacion && xeia?.urlRecomendacion !== "") {
      setVideoRecomendacionShow(true);
    }
    if (xeia?.mensaje) {
      if (xeia.mensaje === "gracias, espero que tengas un buen dia.") {
        dispatchUpdate();
      }
      setMensajes([...mensajes, ["agent", xeia.mensaje]]);
    }
    if (!xeia) {
      setMensajes([]);
    }
  }, [xeia]);

  useEffect(() => {
    if (mapMensajes.length > 0 && currentMapMensajes < mapMensajes.length) {
      setTimeout(() => {
        setMensajes([...mensajes, ["agent", mapMensajes[currentMapMensajes]]]);
        setCurrentMapMensajes(currentMapMensajes + 1);
      }, 3000);
    }
    if (mapMensajes.length > 0 && currentMapMensajes == mapMensajes.length) {
      setMapMensajes([]);
      setCurrentMapMensajes(0);
    }
  }, [mapMensajes, currentMapMensajes]);

  useEffect(() => {
    if (mensajesSpace.current) {
      mensajesSpace.current.scrollTop =
        mensajesSpace.current.scrollHeight - mensajesSpace.current.clientHeight;
    }
  }, [mensajes]);

  return (
    <>
      {xeia != null && (
        <div className="fixed bg-white top-0 bottom-0 left-0 right-0 z-[100]">
          {xeia?.tipoRespuesta === TipoXeia.ERROR && (
            <>
              <div className="flex flex-col justify-between h-full">
                <div className="flex flex-col">
                  {videoRecomendacionShow && (
                    <video
                      preload="auto"
                      className="h-[300px] object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src={`xeia/mejorasErrores/${xeia.urlRecomendacion}`}
                    ></video>
                  )}
                  {!videoRecomendacionShow && (
                    <img
                      ref={imagen}
                      className="h-[300px] object-cover"
                      alt="prueba"
                    />
                  )}
                  <img
                    className="w-[64px] h-[24px] absolute top-4 right-4"
                    src="xeia/LOGO_XEIA_BLACK.svg"
                    alt="logo_xeia"
                  />
                  <p className="font-extrabold text-[48px] text-[#03ABCD] mt-4 ml-auto mr-4">
                    Oops!
                  </p>
                  <div className="flex flex-row">
                    <div className="h-[23px] w-[3px] bg-[#03ABCD] mr-1 ml-4"></div>
                    <p className="font-semibold text-lg">{xeia?.tituloError}</p>
                  </div>
                  <div className="flex flex-row mt-4">
                    <div className="h-[23px] w-[3px] bg-[#03ABCD] mr-1 ml-4"></div>
                    <p className="text-left font-normal text-base w-[80%]">
                      {xeia?.mensaje}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <ButtonModal
                    onClick={props.botonAccionClick}
                    className="bg-[#1E6776] w-[100px] h-[37px] ml-4 mb-8"
                  >
                    <p className="text-white font-semibold text-[15px]">Si</p>
                  </ButtonModal>
                  <ButtonModal
                    onClick={() => {}}
                    className="bg-[#1E6776] w-[100px] h-[37px] ml-4 mb-8"
                  >
                    <p className="text-white font-semibold text-[15px]">No</p>
                  </ButtonModal>
                </div>
              </div>
            </>
          )}
          {xeia?.tipoRespuesta === TipoXeia.CHAT && (
            <div className="flex flex-col justify-between h-full w-full">
              <div className="h-[280px]">
                {xeia.tipoPantalla === Pantallas.ERRORDECLARACION &&
                  !dudaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src="xeia/STANDBY.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla === Pantallas.ERRORDECLARACION &&
                  dudaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      typeof="video/mp4"
                      src="xeia/RENTAxeia.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla === Pantallas.INICIOXEIA &&
                  !bienvenidaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src="xeia/DEMOintro.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla === Pantallas.INICIOXEIA &&
                  bienvenidaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src="xeia/STANDBY.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla !== Pantallas.INICIOXEIA &&
                  xeia.tipoPantalla !== Pantallas.ERRORDECLARACION &&
                  !dudaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src="xeia/TIENES_ALGUNA_DUDA.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla !== Pantallas.INICIOXEIA &&
                  xeia.tipoPantalla !== Pantallas.ERRORDECLARACION &&
                  dudaFinalizo && (
                    <video
                      preload="auto"
                      className="h-full w-full object-cover"
                      autoPlay
                      playsInline
                      muted
                      loop
                      typeof="video/mp4"
                      src="xeia/STANDBY.mp4"
                    ></video>
                  )}
                {xeia.tipoPantalla === Pantallas.INICIOXEIA && (
                  <img
                    className="absolute w-[64px] h-[24px] ml-auto top-4 right-4"
                    src="anteia/EmpresaLogo.svg"
                    alt="logo_xeia"
                  />
                )}
                {xeia.tipoPantalla !== Pantallas.INICIOXEIA &&
                  xeia.tipoPantalla !== Pantallas.ERRORDECLARACION && (
                    <img
                      className="absolute w-[64px] h-[24px] ml-auto top-4 right-4"
                      src="xeia/LOGO_XEIA.svg"
                      alt="logo_xeia"
                    />
                  )}
                <img
                  className="absolute w-6 h-[27px] left-4 top-[240px]"
                  src="/screen_icons/MICRO_OFF.svg"
                  alt="micro off"
                />
                <img
                  className="absolute w-[30px] h-[30px] right-4 top-[240px]"
                  src="/screen_icons/AUDIO_OFF.svg"
                  alt="audio off"
                />
              </div>
              <div
                ref={mensajesSpace}
                className="mx-auto w-[90%] h-full overflow-y-scroll"
              >
                <h4
                  className={`text-[40px] text-left font-extrabold ${
                    xeia.tipoPantalla === Pantallas.INICIOXEIA
                      ? "text-[#F9AA00]"
                      : "text-[#03ABCD]"
                  } mt-4`}
                >
                  Hola!
                </h4>
                <div className="flex flex-col mt-4">
                  <div className="flex flex-row w-full">
                    <div
                      className={`w-[3px] h-[23px] ${
                        xeia.tipoPantalla === Pantallas.INICIOXEIA
                          ? "bg-[#F9AA00]"
                          : "bg-[#03ABCD]"
                      } mb-4 mr-2`}
                    />
                    {xeia.tipoPantalla !== Pantallas.INICIOXEIA &&
                      xeia.tipoPantalla !== Pantallas.ERRORDECLARACION &&
                      !finalizacion && (
                        <p className="text-[14px] font-normal">
                          Tienes alguna pregunta en este momento ?
                        </p>
                      )}
                    {xeia.tipoPantalla !== Pantallas.INICIOXEIA &&
                      xeia.tipoPantalla !== Pantallas.ERRORDECLARACION &&
                      finalizacion && (
                        <p className="text-[14px] font-normal text-left">
                          Te enviaré un correo con la aprovación de tu crédito
                          cuando finalices el proceso
                          <br />a que cuanta bancaria quieres que se te consigne
                          el dinero?
                        </p>
                      )}
                    {xeia.tipoPantalla === Pantallas.INICIOXEIA && (
                      <div className="flex flex-col">
                        <p className="text-[14px] font-normal">
                          Estas son algunas de las opciones de créditos
                          disponibles en este momento:
                        </p>
                        <button className="w-[154px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mt-4 mb-2 pl-2">
                          1. Educativo
                        </button>
                        <button className="w-[154px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mb-2 pl-2">
                          2. Vehiculo
                        </button>
                        <button
                          onClick={libreInversion}
                          className="w-[154px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mb-2 pl-2"
                        >
                          3. Libre Inversión
                        </button>
                        <button className="w-[154px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mb-2 pl-2">
                          4. Vivienda
                        </button>
                      </div>
                    )}
                    {xeia.tipoPantalla === Pantallas.ERRORDECLARACION && (
                      <div className="flex flex-col">
                        <p className="text-[14px] font-normal text-left">
                          Se detecto una incongruencia
                        </p>
                        <p className="text-[14px] font-normal text-left">
                          tu patrimonio no corresponde al declarado en el
                          documento deseas modificar el valor de tu patrimonio?
                        </p>
                        <div className="flex flex-row">
                          <button
                            onClick={si}
                            className="w-[100px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mt-4 mb-2 pl-2"
                          >
                            Si
                          </button>
                          <button className="w-[100px] h-[30px] text-left border-[1px] border-[#03ABCD] rounded-[10px] mt-4 mb-2 ml-4 pl-2">
                            No
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {mensajes.map((message, index) => (
                    <div key={index}>
                      {message[0] === "user" && (
                        <div className="flex flex-row">
                          <p
                            className={`text-[14px] font-semibold ${
                              xeia.tipoPantalla === Pantallas.INICIOXEIA
                                ? "text-[#03ABCD]"
                                : "text-[#BC7100]"
                            } w-[80%] text-right`}
                          >
                            {message[1]}
                          </p>
                          <div
                            className={`w-[3px] h-[23px] ${
                              xeia.tipoPantalla === Pantallas.INICIOXEIA
                                ? "bg-[#03ABCD]"
                                : "bg-[#BC7100]"
                            } mb-4 ml-2`}
                          />
                        </div>
                      )}
                      {message[0] === "agent" && (
                        <div className="flex flex-row">
                          <div
                            className={`w-[3px] h-[23px] ${
                              xeia.tipoPantalla === Pantallas.INICIOXEIA
                                ? "bg-[#F9AA00]"
                                : "bg-[#03ABCD]"
                            } mb-4 mr-2`}
                          />
                          <div className="w-[80%] flex flex-col text-left">
                            <TextTypeEffect
                              color="black"
                              text={message[1]}
                              delay={25}
                            />
                            <br />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-center items-center mb-8">
                <div className="flex items-center h-[37px] w-[277px] bg-[#03ABCD] p-[10px] rounded-[30px] mt-[20px]">
                  <input
                    className={`w-full bg-transparent outline-none text-primary-color placeholder:text-white`}
                    placeholder="Escribe aquí tu consulta"
                    type="text"
                    inputMode="text"
                    required
                    value={texto[1] || ""}
                    onKeyDown={sendMsg}
                    onChange={(event) => {
                      setTexto(["user", event.target.value]);
                    }}
                  />
                  <button onClick={sendMsg}>
                    <img
                      className="h-[18.5px] w-[23.12px]"
                      src="screen_icons/MICROPHONE.svg"
                      alt="send"
                    />
                  </button>
                </div>
                <button
                  className="flex w-[37px] h-[37px] rounded-full bg-[#03ABCD] mt-[20px] ml-2 justify-center items-center"
                  onClick={sendMsg}
                >
                  <img
                    className="h-[16.95px] w-[20px]"
                    src="screen_icons/SEND_MESSAGE.svg"
                    alt="send"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

import React from "react";
import "./Buttons.scss";

export const Button = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const Button1 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button1"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const Button2 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button2"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const ButtonStyle = ({ text, onClick }) => {
  return (
    <button className="buttonStyle" onClick={onClick}>
      {text}
    </button>
  );
};

export const ButtonStyleWithWidth = ({ text, onClick, width }) => {
  return (
    <button className="buttonStyle" onClick={onClick} style={{ width }}>
      {text}
    </button>
  );
};

export const ButtonStyleFile = ({ text, onClick }) => {
  return (
    <>
      <button className="buttonStyleFileUpload" onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export const Input = ({ text, onClick, width, height, onChange }) => {
  return (
    <>
      <input
        className="button2"
        onClick={onClick}
        onChange={onChange}
        style={{ width: width, height: height }}
      >
        {text}
      </input>
    </>
  );
};

export const Button3 = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="button3"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export const ButtonIns = ({ text, onClick, width, height }) => {
  return (
    <>
      <button
        className="buttonIns"
        onClick={onClick}
        style={{ width: width, height: height }}
      >
        {text}
      </button>
    </>
  );
};

export interface ButtonModalProps {
  text?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export const ButtonModal = (props: ButtonModalProps) => {
  return (
    <button
      className={`className="min-w-[200px] min-h-[50px] rounded-[32px] z-10 mt-6 ${
        props.className ?? ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

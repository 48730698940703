export const enum Pantallas {
  INTROPAGE = "IntroPage",
  TERMINOS = "Terminos",
  READMORE = "ReadMore",
  INTROXEIA = "IntroXeia",
  INFOBASICAUSUARIO = "InfoBasicaUsuario",
  ADVICEDOC1 = "AdviceDoc1",
  ADVICEDOC2 = "AdviceDoc2",
  CAPTUREDOC = "CapturaDoc",
  ADVICEFACEMATCH = "AdviceFacematch",
  VERIFICAROTP = "VerificarOTP",
  VERIFICARUBICACION = "VerificarUbicacion",
  VERIFICARCORREO = "VerificarCorreo",
  VERIFICARDATOS = "VerificarDatos",
  VERIFICARDATOS2 = "VerificarDatos2",
  INICIOXEIA = "InicioXeia",
  ERRORDECLARACION = "Declaracion",
}

export const enum TipoMensajePopUp {
  ADVICE = "Advice",
}

export const enum TipoTomaDocumento {
  DOCUMENTFRONT = "DocumentFront",
  DOCUMENTBACK = "DocumentBack",
}

export const enum TipoCamara {
  FACEMATCH = "Facematch",
  DOCUMENT = "Document",
}

export const enum TipoXeia {
  ERROR = "error",
  CHAT = "chat",
}

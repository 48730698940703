import { useEffect, useState } from "react";

export interface TextTypeEffectProps {
  text: string;
  delay: number;
  color: string;
}

export const TextTypeEffect = (props: TextTypeEffectProps) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < props.text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + props.text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, props.delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, props.delay, props.text]);

  return (
    <p className={`${props.color} font-normal text-[14px]`}>{currentText}</p>
  );
};

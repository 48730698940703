import React, { useState, useRef } from "react";
import logo from "./../../assets/logoins.png";
import { Input, ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";

import "./VAdjuntaImg.scss";

function VAdjuntaImg(props) {
  const history = useHistory();
  const inputFile = useRef(null);

  const videoConstraints = {
    facingMode: "environment",
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    let temp = props.data;
    temp["type"] = "img";
    temp["value"] = imageSrc;
    props.setData(temp);
    history.push("./WVisualizar" + history.location.search);
  }, [webcamRef]);
  return (
    <div className="screen1">
      <div style={{ marginTop: "10%" }}>
        <div id="video-stream">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="90%"
            videoConstraints={videoConstraints}
          />
        </div>
      </div>
      <div style={{ marginTop: "1em", paddingBottom: "3em" }}>
        <ButtonStyle
          text="CAPTURAR"
          onClick={(e) => {
            e.preventDefault();
            capture();
          }}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "3em" }}></div>
    </div>
  );
}
export default VAdjuntaImg;

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import { getColorFromCode, updateData } from "../api/magic_forms_2_api";
import useQuery from "../../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import FormField from "../dtos/FormField";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import { infoMessage } from "../../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import {
  addFilledId,
  commitCoreId,
  create,
  getColorsByFlowId,
  getColorsByProcessId,
  getCoreIdLink,
  getElecSigUrl,
  getFlowConfigByProcessId,
  getMagicLink,
} from "../api/FlowApi";
import backgroundImage from "../../assets/backgrounds/007-SKY.jpg";

const NewDummiesElecSigCallback = () => {
  //query params
  const query = useQuery();
  const processId = query.get("processId") ?? "error";
  const filledFormId = query.get("filledFormId") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [needsElecSig, setNeedsElecSic] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  //get colors
  async function getColors() {
    let resp = await getColorsByProcessId(processId);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
  }

  //Load Data
  async function loadData() {
    setDataLoaded(true);
  }

  //Get final Url
  async function redirectFinal() {
    window.location.href = "https://forms.gle/oTwVa7FxLtAJ2SaW9";
  }

  useEffect(() => {
    getColors();
    loadData();
  }, []);
  //render loading
  if (!dataLoaded) {
    return (
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${backgroundImage})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ marginTop: "20vh" }}>
          <ClipLoader loading={true} size={150} color={"#FFFFFF"} />
          <h1
            className="txt-label-title"
            style={{ fontSize: "", marginBottom: "50px" }}
          >
            Espera un momento
          </h1>
        </div>
      </div>
    );
  }
  //Render
  return (
    <div
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${backgroundImage})`,
        position: "fixed",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          left: "50%",
        }}
      >
        <h1
          className="txt-label-title"
          style={{ fontSize: "", marginBottom: "50px" }}
        >
          ¡Excelente!
        </h1>
        <h1
          className="txt-label"
          style={{ fontSize: "", marginBottom: "50px" }}
        >
          Tu proceso ha terminado exitosamente
        </h1>

        <div
          style={{
            marginTop: "40px",
          }}
        ></div>
        <ButtonStyle
          onClick={() => {
            redirectFinal();
          }}
          text="Terminar"
        />
      </div>
    </div>
  );
};
export default NewDummiesElecSigCallback;

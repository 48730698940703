import React, { useEffect, useRef, useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./QVerificacionAfiliacion.scss";

function QVerificacionAfiliacion(props) {
  const history = useHistory();
  const timer = useRef();

  useEffect(() => {
    timer.current = setInterval(() => {
      NextOptions1();
    }, 5000);
  }, []);

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  const NextOptions1 = () => {
    props.redirectmagicCOM();
  };

  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title2">
          Bienvenid@ <br />a tu proceso de afiliación
        </p>
      </div>

      <div style={{ marginTop: "3em", marginBottom: "3em" }}>
        <ButtonStyle
          text="CONTINUAR"
          onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
      </div>

      <p className="title3" style={{ fontWeight: "bold" }}>
        Recuerde que debes ser <br /> mayor de edad para afiliarte.
      </p>
    </div>
  );
}
export default QVerificacionAfiliacion;

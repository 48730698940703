import axios from "axios";
import localforage from "localforage";

// let baseUrl = process.env.REACT_APP_BASE_URL || "";
const baseUrl = ""
const baseUrlXeia = "https://atenea.dev.api.anteia.co/"

const axiosApiInstance = axios.create({ baseURL: baseUrl });
const axiosApiInstanceXeia = axios.create({ baseURL: baseUrlXeia });
const axiosApiInstanceNoToken = axios.create({ baseURL: baseUrl });

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const currentToken = await localforage.getItem("token");
    if (currentToken && currentToken !== "") {
      config.headers.Authorization = `Bearer ${currentToken}`;
    }
    config.validateStatus = (status) => {
      return status < 600;
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosApiInstanceNoToken.interceptors.request.use(
  async (config) => {
    config.validateStatus = (status) => {
      return status < 600;
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosApiInstanceXeia.interceptors.request.use(
  async (config) => {
    config.validateStatus = (status) => {
      return status < 600;
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export { axiosApiInstance, axiosApiInstanceNoToken, axiosApiInstanceXeia };

import React, { useState, useRef } from "react";
import logo from "./../../assets/logoins.png";
import { Input, ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";

import "./VAdjunta.scss";

function VAdjunta(props) {
  const history = useHistory();
  const inputFile = useRef(null);

  const NextOptions1 = () => {
    inputFile.current.click();
  };
  const admin = () => {
    let temp = props.data;
    temp["type"] = "file";
    temp["value"] = inputFile.current.value;
    props.setData(temp);
    history.push("./WVisualizar" + history.location.search);
  };

  const NextOptions2 = () => {
    history.push("./VAdjuntaImg" + history.location.search);
  };

  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title2">
          Para continuar, <br /> por favor adjuntar <br /> el comprobante de{" "}
          <br /> pago
        </p>
      </div>

      <div style={{ marginTop: "3em" }}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={admin}
        />

        <ButtonStyle
          onClick={(e) => NextOptions1()}
          text="Adjuntar archivo"
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="Tomar fotografía"
          onClick={(e) => NextOptions2()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default VAdjunta;

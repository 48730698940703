import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./JSelecciona.scss";

function JSelecciona(props) {
  const history = useHistory();

  const NextOptions = (type) => {
    let temp = props.data;
    temp["tipo"] = type;
    props.setData(temp);
    props.getData();

    history.push("./KIngresaCuentaAhorros" + history.location.search);
  };

  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title2">Selecciona el tipo de plan</p>
      </div>

      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="Premium"
          onClick={() => NextOptions("Premium")}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="Estándar"
          onClick={() => NextOptions("Estándar")}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="Básico"
          onClick={() => NextOptions("Básico")}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="Nómina"
          onClick={() => NextOptions("Nómina")}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default JSelecciona;

import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./InitialForm.scss";
import { TextFieldStyleDummies } from "../../utils/TextFieldStyle/TextFieldStyle";
import NumberFormat from "react-number-format";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormGroup, FormControlLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "react-datepicker";
import { getMonth, getYear, parse } from "date-fns";
import imgFlecha from "../../assets/flechaArriba.png";
import { ButtonStyleFile } from "../../utils/Buttons/Buttons";

const useStyles = makeStyles({
  select: {
    "& .MuiInputBase-input": {
      fontFamily: "'Open Sans', sans-serif",
      color: "rgba(255,255,255, 0.8)",
    },
    // use opensans as font
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    outlineColor: "white",
    borderColor: "white !important",
    fieldset: {
      fontFamily: "'Open Sans', sans-serif",
      borderColor: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: "'Open Sans', sans-serif",
        borderColor: "rgba(255,255,255, 0.5)",
        borderRadius: "10px",
      },
      "& input": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(255,255,255, 0.8)",
        textAlign: "center",
      },
      "&:hover": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(255,255,255, 0.8)",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(255,255,255, 0.8)",
          outlineColor: "rgba(255,255,255, 0.8)",
        },
      },
    },

    "& .MuiInputLabel-root": {
      color: "rgba(255,255,255, 0.5)",
      textAlign: "center",
      fontFamily: "'Open Sans', sans-serif",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(1)",
      width: "max-content",
      "&.MuiInputLabel-shrink": {
        top: "-6px",
        left: "15px",
        transform: "translate(0, 0) scale(0.75)",
      },

      "&.Mui-focused": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(255,255,255, 0.8)",
      },
    },

    "& .MuiSvgIcon-root": {
      fill: "rgba(255,255,255, 0.8)",
    },
  },
  inputRoot: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "white",
    borderColor: "white",
    outlineColor: "white",
  },
  input: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "white",
    borderColor: "white",
    outlineColor: "white",
  },
  icon: {
    whiteSpace: "normal !important",
    fill: "white",
    outlineColor: "white",
    borderColor: "white !important",
  },
  root: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "white",
    outlineColor: "white",
    borderColor: "white !important",
    fieldset: {
      borderColor: "white !important",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: "'Open Sans', sans-serif",
        borderColor: "rgba(255,255,255, 0.5)",
        borderRadius: "10px",
      },
      "& input": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(255,255,255, 0.8)",
        textAlign: "center",
      },
      "&:hover": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(255,255,255, 0.8)",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(255,255,255, 0.8)",
          outlineColor: "rgba(255,255,255, 0.8)",
        },
      },
    },

    "& .MuiInputLabel-root": {
      color: "rgba(255,255,255, 0.5)",
      textAlign: "center",
      fontFamily: "'Open Sans', sans-serif",
      "&.Mui-focused": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(255,255,255, 0.8)",
      },
    },

    "& .MuiAutocomplete-endAdornment": {
      "& .MuiSvgIcon-root": {
        fill: "rgba(255,255,255, 0.8)",
        stroke: "rgba(255,255,255, 0.8)",
      },
    },
    "& .MuiAutocomplete-clearIndicator": {
      height: "30px",
      width: "30px",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        fill: "rgba(255,255,255, 0.8)",
        marginRight: "10px",
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      height: "30px",
      width: "40px",
      "& .MuiSvgIcon-root": {
        fill: "rgba(255,255,255, 0.8)",
      },
    },
  },
  inputStuff: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
  },
  text: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "white ",
    outlineColor: "white",
    borderColor: "white !important",
    fieldset: {
      whiteSpace: "normal !important",
      borderColor: "white !important",
    },
  },
});

const useStyles2 = makeStyles({
  select: {
    "& .MuiInputBase-input": {
      fontFamily: "'Open Sans', sans-serif",
      color: "rgba(0,0,0, 0.8)",
    },
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    outlineColor: "black",
    borderColor: "black !important",
    fieldset: {
      borderColor: "black !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: "'Open Sans', sans-serif",
        borderColor: "rgba(0,0,0, 0.5)",
        borderRadius: "10px",
      },
      "& input": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(0,0,0, 0.8)",
        textAlign: "center",
      },
      "&:hover": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(0,0,0, 0.8)",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(0,0,0, 0.8)",
          outlineColor: "rgba(0,0,0, 0.8)",
        },
      },
    },

    "& .MuiInputLabel-root": {
      color: "rgba(0,0,0, 0.5)",
      textAlign: "center",
      fontFamily: "'Open Sans', sans-serif",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "max-content",
      transform: "translate(-50%,-50%) scale(1)",
      "&.MuiInputLabel-shrink": {
        fontFamily: "'Open Sans', sans-serif",
        top: "-6px",
        left: "15px",
        transform: "translate(0, 0) scale(0.75)",
      },

      "&.Mui-focused": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(0,0,0, 0.8)",
      },
    },

    "& .MuiSvgIcon-root": {
      fill: "rgba(0,0,0, 0.8)",
    },
  },
  inputRoot: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    borderColor: "black",
    outlineColor: "black",
  },
  input: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    borderColor: "black",
    outlineColor: "black",
  },
  icon: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    fill: "black",
    outlineColor: "black",
    borderColor: "black !important",
  },
  root: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    outlineColor: "black",
    borderColor: "black !important",
    fieldset: {
      borderColor: "black !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: "'Open Sans', sans-serif",
        borderColor: "rgba(0,0,0, 0.5)",
        borderRadius: "10px",
      },
      "& input": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(0,0,0, 0.8)",
        textAlign: "center",
      },
      "&:hover": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(0,0,0, 0.8)",
        },
      },
      "&.Mui-focused": {
        "& fieldset": {
          fontFamily: "'Open Sans', sans-serif",
          borderColor: "rgba(0,0,0, 0.8)",
          outlineColor: "rgba(0,0,0, 0.8)",
        },
      },
    },

    "& .MuiInputLabel-root": {
      fontFamily: "'Open Sans', sans-serif",
      color: "rgba(0,0,0, 0.5)",
      textAlign: "center",

      "&.Mui-focused": {
        fontFamily: "'Open Sans', sans-serif",
        color: "rgba(0,0,0, 0.8)",
      },
    },

    "& .MuiAutocomplete-endAdornment": {
      "& .MuiSvgIcon-root": {
        fill: "rgba(0,0,0, 0.8)",
        stroke: "rgba(0,0,0, 0.8)",
        marginRight: "10px",
      },
    },
    "& .MuiAutocomplete-clearIndicator": {
      height: "30px",
      width: "30px",
      marginRight: "5px",
      "& .MuiSvgIcon-root": {
        fill: "rgba(0,0,0, 0.8)",
        stroke: "rgba(0,0,0, 0.8)",
        marginRight: "10px",
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      height: "30px",
      width: "40px",
      "& .MuiSvgIcon-root": {
        fill: "rgba(0,0,0, 0.8)",
        stroke: "rgba(0,0,0, 0.8)",
      },
    },
  },
  inputStuff: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
  },
  text: {
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "normal !important",
    color: "black",
    outlineColor: "black",
    borderColor: "black !important",
    fieldset: {
      whiteSpace: "normal !important",
      borderColor: "black !important",
    },
  },
});

const years = Array.from(new Array(200), (x, i) => i + 1900);
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/**
 * types: text, money, multiple
 * @param {*} param0
 * @param {Array} options options
 * @param {FieldValidator} validator
 * @returns
 */
export const GenericField2 = ({
  label,
  onChange,
  value,
  type,
  disabled = false,
  defaultValue,
  fieldType,
  title,
  options,
  description,
  supportsOther,
  fileTypes,
  validator,
  darkMode = true,
}) => {
  const getClassName = (baseClassName) =>
    darkMode ? `${baseClassName}-dark` : baseClassName;
  const DateInput = forwardRef(({ value, onClick, disabled }, ref) => (
    <fieldset
      disabled={disabled}
      className={getClassName("fieldset-class-field")}
      onClick={onClick}
      ref={ref}
    >
      <input
        disabled={disabled}
        className={getClassName("fieldset-input-class-field")}
        placeholder=" "
        style={{ border: "none" }}
        type="text"
        value={value}
        required
      />
      <legend className={getClassName("fieldset-label-class-field")}>
        {title}
      </legend>
    </fieldset>
  ));

  let [otherVal, setOtherVal] = useState("");
  let [multiVal, setMultiVal] = useState(value);
  const [dateE, setDate] = useState(null);
  const classes = useStyles();
  const classesLight = useStyles2();
  //dd special
  const [otherDdAct, setOtherDdAct] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  //file ref
  const fileRef = useRef(null);
  useEffect(() => {
    if (fieldType === "date" && value) {
      try {
        let nDate = parse(value, "dd/MM/yyyy", new Date());
        console.log("pasing date success for value: " + value);
        console.log(nDate);
        if (isNaN(nDate.getTime())) {
          throw "Error Date";
        }
        setDate(nDate);
      } catch (e) {
        console.log("error on date parsing for value: " + value);
      }
    }
  }, []);

  // Money filed type
  if (fieldType === "money") {
    return (
      <div style={{ margin: "25px 0" }}>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={title}
          value={value}
          type={type}
          defaultValue={defaultValue}
          FormatComponent={MoneyNumberFormat}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Money filed type
  if (fieldType === "moneyCr") {
    return (
      <div style={{ margin: "25px 0" }}>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={title}
          value={value}
          type={type}
          defaultValue={defaultValue}
          FormatComponent={MoneyNumberFormatCostaRica}
          darkMode={darkMode}
        />
      </div>
    );
  }
  if (fieldType === "moneyNi") {
    return (
      <div style={{ margin: "25px 0" }}>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={title}
          value={value}
          type={type}
          defaultValue={defaultValue}
          FormatComponent={MoneyNumberFormatNicaragua}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Multiple choice field
  else if (fieldType === "multipleChoice") {
    let checks = [];
    options.map((val, i) => {
      let c = (
        <FormControlLabel
          key={i}
          className="form-style"
          control={
            <Checkbox
              className="checkbox-style"
              onChange={(e) => {
                onChange(val, e.target.checked);
              }}
            />
          }
          label={val}
        />
      );
      checks.push(c);
    });
    return (
      <div className="row">
        <p className="txt-label">{title}</p>
        <FormGroup>{checks}</FormGroup>
      </div>
    );
  }
  // Single choice field
  else if (fieldType === "singleChoice") {
    let a = "";
    let checks = [];
    let values = [];
    options.map((val, i) => {
      values.push(val);
      let c = (
        <FormControlLabel
          key={i}
          className="form-style"
          style={{ textAlign: "start", marginBottom: "10px" }}
          value={val}
          control={<Radio className="checkbox-style" />}
          label={val}
        />
      );
      checks.push(c);
    });
    let c = <div></div>;
    if (supportsOther) {
      values.push("otro");
      c = (
        <div key={"otro"} style={{ marginTop: "3vh" }}>
          <TextFieldStyleDummies
            onChange={(e) => {
              let val = e.target.value;
              setOtherVal(val);
              setMultiVal("");
              onChange(`:otro:${val}`, e.target.value !== "");
              // values.forEach((val2) => {
              //   onChange(val, false);
              // });
            }}
            disabled={disabled}
            label={"Otro"}
            value={otherVal}
            darkMode={darkMode}
          />
        </div>
      );
    }
    return (
      <div className="row" style={{ padding: "1px" }}>
        <p className="txt-label">{title}</p>
        <FormGroup>
          <RadioGroup
            onChange={(e) => {
              let selected = e.target.value;
              setMultiVal(selected);
              onChange(selected);
              // values.forEach((val) => {
              //   onChange(val, selected === val);
              // });
              if (supportsOther) {
                // onChange(":otro:", false);
                setOtherVal("");
                // c.value = "";
              }
            }}
            value={multiVal}
          >
            {checks}
          </RadioGroup>
        </FormGroup>
        {c}
      </div>
    );
  }
  // Dropdown field
  else if (fieldType === "dropdown") {
    let a = "";
    let items = [];
    options.map((val, i) => {
      let c = (
        <MenuItem
          key={i}
          value={val}
          classes={{ root: classes.inputStuff }}
          style={{ whiteSpace: "normal !important" }}
        >
          {val}
        </MenuItem>
      );
      items.push(c);
    });
    if (supportsOther) {
      items.push(
        <MenuItem key={"otherr"} value={"Otro"}>
          {"Otro"}
        </MenuItem>
      );
    }
    return (
      <div
        style={{
          margin: "30px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
        }}
      >
        {title && title.length >= 37 && (
          <p
            style={{
              margin: "0 0 8px 0",
              fontSize: "15px",
              fontWeight: 600,
              color: "white",
              textAlign: "start",
            }}
          >
            {title}
          </p>
        )}
        <FormGroup
          style={{ width: "100%" }}
          classes={{ root: darkMode ? classes.text : classesLight.text }}
        >
          <TextField
            SelectProps={{
              IconComponent: () => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: "0px",
                      cursor: "pointer",
                      pointerEvents: "none",
                      zIndex: "-1000",
                    }}
                  >
                    <svg
                      style={{
                        cursor: "pointer",
                        zIndex: "-1000",
                        marginRight: "13px",
                        pointerEvents: "none",
                      }}
                      width="1"
                      height="20"
                      viewBox="0 0 1 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        x2="0.5"
                        y2="20"
                        stroke={darkMode ? "white" : "black"}
                        stroke-opacity="0.4"
                      />
                    </svg>

                    <svg
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        zIndex: "-1000",
                        pointerEvents: "none",
                      }}
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.90735e-06 3.33882C0.00308228 2.80387 0.116661 2.27562 0.333303 1.78865C0.560415 1.26198 0.931032 0.813264 1.4009 0.496092C1.87076 0.17892 2.42001 0.00669988 2.98305 0L17.015 0C17.578 0.00669988 18.1273 0.17892 18.5972 0.496092C19.067 0.813264 19.4376 1.26198 19.6648 1.78865C19.9448 2.39465 20.0526 3.06888 19.976 3.73449C19.8994 4.4001 19.6414 5.03032 19.2315 5.55335L12.2155 14.2411C11.9501 14.5818 11.6131 14.8569 11.2293 15.046C10.8456 15.2351 10.425 15.3333 9.99903 15.3333C9.57302 15.3333 9.15249 15.2351 8.76874 15.046C8.385 14.8569 8.04791 14.5818 7.78258 14.2411L0.766592 5.55335C0.269764 4.92711 -0.000940323 4.1451 1.90735e-06 3.33882Z"
                        fill={darkMode ? "white" : "black"}
                      />
                    </svg>
                  </div>
                );
              },
            }}
            classes={{ root: darkMode ? classes.select : classesLight.select }}
            select
            variant="outlined"
            placeholder="Seleccione una opción"
            value={multiVal}
            label={title && title.length < 37 ? title : "Seleccione una opción"}
            onChange={(e) => {
              setMultiVal(e.target.value);
              onChange(e.target.value);
              if (e.target.value === "Otro") {
                setOtherDdAct(true);
              } else {
                setOtherDdAct(false);
              }
            }}
            disabled={disabled}
          >
            {items}
          </TextField>
        </FormGroup>
        {/* Other field */}
        {otherDdAct && (
          <div style={{ margin: "25px 0" }}>
            <p className="txt-label">{"¿Cual?"}</p>
            <TextFieldStyleDummies
              onChange={(ev) => {
                setOtherVal(ev.target.value);
                onChange(ev.target.value);
              }}
              disabled={disabled}
              label={"¿Cual?"}
              value={otherVal}
              type={type}
              defaultValue={defaultValue}
              darkMode={darkMode}
            />
          </div>
        )}
      </div>
    );
  }
  //Dropdown Dynamic
  else if (
    fieldType === "dropdown_dynamic" ||
    fieldType === "dropdown_from_state"
  ) {
    let itemsString = [];
    options.map((val, i) => {
      itemsString.push({ label: val });
    });

    return (
      <div
        style={{
          margin: "30px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
        }}
      >
        <div style={{ height: "30px" }}></div>
        {title && title.length >= 37 && (
          <p
            style={{
              margin: "0 0 8px 0",
              fontSize: "15px",
              fontWeight: 600,
              color: "white",
              textAlign: "start",
            }}
          >
            {title}
          </p>
        )}{" "}
        <FormGroup
          sx={{
            color: darkMode ? "white" : "black",
            borderColor: darkMode ? "white" : "black",
            outlineColor: darkMode ? "white" : "black",
            accentColor: darkMode ? "white" : "black",
          }}
          style={{ width: "100%" }}
          classes={{ root: !darkMode ? classes.text : classesLight.text }}
        >
          <Autocomplete
            sx={{ textAlign: "center" }}
            classes={darkMode ? classes : classesLight}
            value={multiVal}
            disabled={disabled}
            clearIcon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: "0px",
                  height: "20px",
                  zIndex: "-1000",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <line
                    x1="2"
                    y1="2"
                    x2="18"
                    y2="18"
                    stroke={darkMode ? "white" : "black"}
                    stroke-width="2"
                  />
                  <line
                    x1="2"
                    y1="18"
                    x2="18"
                    y2="2"
                    stroke={darkMode ? "white" : "black"}
                    stroke-width="2"
                  />
                </svg>
              </div>
            }
            popupIcon={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: "0px",
                  zIndex: "-1000",
                }}
              >
                <svg
                  style={{
                    cursor: "pointer",
                    zIndex: "-1000",
                    marginRight: "10px",
                  }}
                  width="1"
                  height="20"
                  viewBox="0 0 1 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.5"
                    x2="0.5"
                    y2="20"
                    stroke={darkMode ? "white" : "black"}
                    stroke-opacity="0.4"
                  />
                </svg>

                <svg
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                    zIndex: "-1000",
                  }}
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.90735e-06 3.33882C0.00308228 2.80387 0.116661 2.27562 0.333303 1.78865C0.560415 1.26198 0.931032 0.813264 1.4009 0.496092C1.87076 0.17892 2.42001 0.00669988 2.98305 0L17.015 0C17.578 0.00669988 18.1273 0.17892 18.5972 0.496092C19.067 0.813264 19.4376 1.26198 19.6648 1.78865C19.9448 2.39465 20.0526 3.06888 19.976 3.73449C19.8994 4.4001 19.6414 5.03032 19.2315 5.55335L12.2155 14.2411C11.9501 14.5818 11.6131 14.8569 11.2293 15.046C10.8456 15.2351 10.425 15.3333 9.99903 15.3333C9.57302 15.3333 9.15249 15.2351 8.76874 15.046C8.385 14.8569 8.04791 14.5818 7.78258 14.2411L0.766592 5.55335C0.269764 4.92711 -0.000940323 4.1451 1.90735e-06 3.33882Z"
                    fill={darkMode ? "white" : "black"}
                  />
                </svg>
              </div>
            }
            onChange={(e, nv) => {
              if (!nv) return;
              // console.log(e);
              console.log(nv);
              setMultiVal(nv);
              onChange(nv.label);
              // onChange()
              // setMultiVal(e.target.value);
              // onChange(e.target.value);
              if (e.target.value === "Otro") {
                setOtherDdAct(true);
              } else {
                setOtherDdAct(false);
              }
            }}
            disablePortal
            options={itemsString}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  title && title.length < 37 ? title : "Seleccione una opción"
                }
                variant="outlined"
                classes={{
                  root: darkMode ? classes.select : classesLight.select,
                }}
                sx={{
                  color: darkMode ? "white !important" : "black !important",
                }}
                color={darkMode ? "white" : "black"}
              />
            )}
          />
        </FormGroup>
        <div style={{ height: "30px" }}></div>
        {/* Other field */}
        {otherDdAct && (
          <div style={{ margin: "25px 0" }}>
            <p className="txt-label">{"¿Cual?"}</p>
            <TextFieldStyleDummies
              onChange={(ev) => {
                setOtherVal(ev.target.value);
                onChange(ev.target.value);
              }}
              disabled={disabled}
              label={"¿Cual?"}
              value={otherVal}
              type={type}
              defaultValue={defaultValue}
              darkMode={darkMode}
            />
          </div>
        )}
      </div>
    );
  }
  // File field
  else if (fieldType === "file") {
    // if(fileTypes) {

    // }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0 10px 0",
        }}
      >
        {/* <p className="txt-label">{title}</p> */}
        {/* <input type="file" onChange={onChange} /> */}
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={onChange}
        />
        <ButtonStyleFile
          onClick={() => {
            fileRef.current.click();
          }}
          text={value ? `Remplazar ${title}` : title}
        />
        {value && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              className="txt-label"
              style={{
                margin: "10px 0 0 0",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Has adjuntado el archivo:
            </p>
            <p
              className="txt-label"
              style={{ margin: "5px 0 10px 0", textAlign: "center" }}
            >
              {value}
            </p>
          </div>
        )}
      </div>
    );
  }
  // Number field
  else if (fieldType === "number") {
    return (
      <div style={{ margin: "25px 0" }}>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={title}
          value={value}
          type={"number"}
          defaultValue={defaultValue}
          darkMode={darkMode}
        />
      </div>
    );
  }
  // Date field
  else if (fieldType === "date") {
    return (
      <div className="row" style={{ padding: "1px" }}>
        <DatePicker
          customInput={<DateInput />}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
          // format="dd/MM/yyyy"
          onChange={(e) => {
            console.log("changed something!");
            setDate(e);
            if (!e) return;
            console.log(e);
            let date2 = e;
            let month = date2.getMonth() + 1;
            let year = date2.getFullYear();
            let day = date2.getDate();
            let dateString = `${day}/${month}/${year}`;
            onChange(dateString);
          }}
          dateFormat="dd/MM/yyyy"
          selected={dateE}
          style={{ color: "black" }}
          wrapperClassName="datePicker"
          disabled={disabled}
        />
      </div>
    );
  }
  // Label Field
  else if (fieldType === "label" || fieldType === "labelSummary") {
    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "dynamic_label") {
    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title.replace("{{}}", value)}
        </p>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {description}
        </p>
      </div>
    );
  } else if (fieldType === "read_more") {
    return (
      <div className="row" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
        <p
          className="txt-label"
          style={{ marginLeft: "10vw", marginRight: "10vw" }}
        >
          {title}
        </p>
        {showDesc && (
          <div
            style={{
              height: "30vh",
              overflow: "auto",
            }}
          >
            <p
              className="txt-label"
              style={{
                marginLeft: "2vw",
                marginRight: "2vw",
                fontSize: "16px",
                textAlign: "start",
              }}
            >
              {description}
            </p>
          </div>
        )}
        {!showDesc && (
          <p
            className="txt-label"
            style={{
              marginLeft: "10vw",
              marginRight: "10vw",
              fontSize: "16px",
            }}
          >
            {description.substring(0, 40) + "..."}
          </p>
        )}
        {!showDesc && (
          <p
            className="txt-label"
            onClick={() => {
              setShowDesc(true);
            }}
          >
            {"Leer más    "}{" "}
            <img
              src={imgFlecha}
              alt="flecha abajo"
              className="img-flecha-inv"
              style={{
                height: "12px",
                width: "16px",
                transform: [{ rotate: "180deg" }],
              }}
            ></img>
          </p>
        )}
        {showDesc && (
          <p
            className="txt-label"
            style={{ fontSize: "16px" }}
            onClick={() => {
              setShowDesc(false);
            }}
          >
            {"Leer menos   "}
            <img
              alt="flecha arriba"
              src={imgFlecha}
              style={{ height: "12px", width: "16px" }}
            ></img>
          </p>
        )}
      </div>
    );
  }

  // Default field
  else {
    return (
      <div style={{ margin: "25px 0" }}>
        <TextFieldStyleDummies
          onChange={(ev) => {
            onChange(ev.target.value);
          }}
          disabled={disabled}
          label={title}
          value={value}
          type={type}
          defaultValue={defaultValue}
          darkMode={darkMode}
        />
      </div>
    );
  }
};

function MoneyNumberFormat(props) {
  return (
    <NumberFormat
      {...props}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$"}
      // isNumericString
    />
  );
}

function MoneyNumberFormatCostaRica(props) {
  return (
    <NumberFormat
      {...props}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"₡"}
      // isNumericString
    />
  );
}

function MoneyNumberFormatNicaragua(props) {
  return (
    <NumberFormat
      {...props}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"C$"}
      // isNumericString
    />
  );
}

import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./TVamosCredito.scss";

function TVamosCredito(props) {
  const history = useHistory();

  const NextOptions1 = () => {
    props.redirectmagicCOM();
  };
  const NextOptions2 = () => {
    history.push("./HQueDeseaHacer" + history.location.search);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: "20px", color: "white" }}>
          Bienvenido al proceso de solicitud de crédito
        </p>
        <p style={{ fontSize: "20px", color: "white" }}>
          Recuerde que debe ser mayor de edad para poder obtener un crédito.
        </p>
      </div>

      <div style={{ marginTop: "3em" }}>
        <ButtonStyle
          text="CONTINUAR"
          onClick={() => NextOptions1()}
          width="177px"
          height="5vh"
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <ButtonStyle
          text="INICIO"
          onClick={() => NextOptions2()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default TVamosCredito;

import React, { useState } from "react";
import "./NPreFirmaSeguro.scss";
import { TextFieldStyleSS } from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";
import { TextFieldStyleSSelect } from "../../utils/TextFieldStyle/TextFieldStyle";

function NPreFirmaSeguro(props) {
  const history = useHistory();
  const [data, setData] = useState("Pago biométrico");

  const Next = () => {
    props.redirectmfa();
  };
  const userChange = (name, value) => {
    let temp = props.data;
    temp[name] = value;
    props.setData(temp);
  };

  return (
    <div className="enterdata-style">
      <p>
        Selecciona tu <br /> medio de pago
      </p>

      <TextFieldStyleSSelect
        data={
          <FormControl variant="outlined">
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: "white" }}
              shrink
            >
              Medios de pago
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data}
              style={{ color: "white", borderColor: "white", width: "80vw" }}
              onChange={(e) => {
                setData(e.target.value);
              }}
              input={<OutlinedInput notched label={"Medios de pago"} />}
            >
              <MenuItem value={"Pago biométrico"}>Pago biométrico</MenuItem>
              <MenuItem value={"Transferencia Bancaria"}>
                Transferencia Bancaria
              </MenuItem>
              <MenuItem value={"Tarjeta de crédito"}>
                Tarjeta de crédito
              </MenuItem>
            </Select>
          </FormControl>
        }
      />

      <div className="btn-enter">
        <ButtonStyle
          text="Continuar"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}

export default NPreFirmaSeguro;

import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./YFinalCredito.scss";

function YFinalCredito() {
  const history = useHistory();

  const NextOptions = () => {
    history.push("./HQueDeseaHacer" + history.location.search);
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title1">¡Excelente!</p>
        <p className="title2">
          Tu credito ha sido <br /> solicitado exitosamente
        </p>
      </div>

      <div style={{ marginTop: "8em" }}>
        <ButtonStyle
          text="Volver al menú principal"
          onClick={() => NextOptions()}
          width="177px"
          height="3em"
        />
      </div>
    </div>
  );
}
export default YFinalCredito;

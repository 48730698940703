import axios from "axios";
import DrawableFormTemplate from "../dtos/DrawableFormTemplate";
import FilledForm from "../dtos/FilledForm";

import OptionsList from "../dtos/OptionsList";
import { axiosApiInstanceXeia } from "../helpers/interceptor";

// const server = "http://localhost:8087";
// const server = "https://services.anteia.co";
const server = "https://magicforms-transact.dev.api.anteia.co/";
const sendMessagePath = "/inteligentPersonalAssistant/sendMessage";
export async function getFromCode(code: string): Promise<GetFromCodeResponse> {
  console.log("getDataFromCode: " + code);
  try {
    let response = await axios.get<GetFromCodeResponse>(
      `${server}/flow/filledFormFromCode?code=${code}`
    );

    if (response.status === 200) {
      console.log("getDataFromCode: success");
      console.log(response.data);
      let data = response.data;
      console.log(data.filledForm.conditionals);
      return response.data;
    } else {
      console.log("getDataFromCode: error");
      return {
        errorMessage:
          "Error: Hay un inconveniente con tu código, verifica que no esté vencido",
      } as GetFromCodeResponse;
    }
  } catch (err: any) {
    console.log("getDataFromCode: error", err.response.data);
    return { errorMessage: err.response.data } as GetFromCodeResponse;
  }
}

export async function getDrawable(
  id: string
): Promise<DrawableFormTemplate | void> {
  console.log("getDrawable: " + id);
  try {
    let response = await axios.get<DrawableFormTemplate>(
      `http://localhost:8087/hello/drawable/${id}`
    );

    if (response.status === 200) {
      console.log("getDataFromCode: success");
      console.log(response.data);
      let data = response.data;
      console.log(data);
      return response.data;
    } else {
      console.log("getDataFromCode: error");
      return undefined;
    }
  } catch (err: any) {
    console.log("getDataFromCode: error", err.response.data);
    return undefined;
  }
}

export const pauseFormAPI = async (
  code: string
): Promise<PauseFormResponse> => {
  try {
    let response = await axios.get<PauseFormResponse>(
      `${server}/flow/pauseForm?code=${code}`
    );
    return response.data;
  } catch (err: any) {
    let error = "";
    if (err.response.status !== 200) {
      error = err.response.data;
    }
    return { sentEmail: false, error };
  }
};

export const verifyPauseAPI = async (
  req: PauseVerifyRequest
): Promise<PauseVerifyResponse> => {
  try {
    let response = await axios.get<PauseVerifyResponse>(
      `${server}/flow/verifyPauseCode?code=${req.code}&pauseCode=${req.pauseCode}`
    );
    return response.data;
  } catch (err: any) {
    let error = "";
    if (err.response.status !== 200) {
      error = err.response.data;
    }
    return { genPath: "", error };
  }
};

export async function updateData(
  data: { [key: string]: string },
  conditionals: { [key: string]: boolean },
  pageNumber: number,
  token: string
): Promise<GenericStatus> {
  console.log("Update data");
  let req = {
    data,
    conditionals,
    pageNumber,
  };
  let response = await axios.post(`${server}/flow/updateData`, req, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (response.status === 200) {
    return { success: true };
  } else {
    return {
      success: false,
      errorMessage: "Ocurrió un error al actualizar tus datos",
    };
  }
}

export async function getColorFromCode(code: string): Promise<BrandConfig> {
  console.log("get color from code: " + code);
  try {
    let response = await axios.get<BrandConfig>(
      `${server}/flow/brandConfigFromCode?code=${code}`
    );

    if (response.status === 200) {
      console.log("success");
      console.log(response.data);
      return response.data;
    } else {
      console.log("error status: " + response.status);
      return {
        backgroundColor1: "#047bd6",
        backgroundColor2: "#000e1a",
        logoUrl: "",
        darkMode: false,
      };
    }
  } catch (err) {
    console.log("error: " + err);
    return {
      backgroundColor1: "#047bd6",
      backgroundColor2: "#000e1a",
      logoUrl: "",
      darkMode: false,
    };
  }
}

export async function uploadFile(
  request: UploadFileRequest,
  token: string
): Promise<GenericStatus> {
  console.log("Upload File: " + request.fileName);
  let response = await axios.post(`${server}/flow/uploadFile`, request, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (response.status === 200) {
    return { success: true };
  } else {
    return {
      success: false,
      errorMessage: "Ocurrió un error al subir el archivo",
    };
  }
}

export async function generatePdf(token: string): Promise<GenericStatus> {
  console.log("generate PDF");
  let response = await axios.get(`${server}/flow/generatePdf`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (response.status === 200) {
    return { success: true };
  } else {
    return {
      success: false,
      errorMessage: "Ocurrió un error generando tu formulario",
    };
  }
}

export async function sendWebhooks(token: string): Promise<GenericStatus> {
  console.log("generate PDF");
  let response = await axios.get(`${server}/flow/sendWebhooks`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (response.status === 200) {
    return { success: true };
  } else {
    return {
      success: false,
      errorMessage: "Ocurrió un error generando tu formulario",
    };
  }
}

export async function getRedirectionUrl(
  token: string
): Promise<RedirectionUrlResponse> {
  console.log("get redir URL");
  try {
    let response = await axios.get<RedirectionUrlResponse>(
      `${server}/flow/getRedirectionUrl`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.status === 200) {
      console.log(response.data);
      return { success: true, url: response.data.url };
    } else {
      return {
        success: false,
        errorMessage: "Ocurrió un error al intentar redirigirte",
      };
    }
  } catch (e) {
    return {
      success: false,
      errorMessage: "Ocurrió un error al intentar redirigirte",
    };
  }
}

export async function getOptionsList(
  token: string,
  listId: string
): Promise<OptionsList> {
  console.log("get redir URL");
  try {
    let response = await axios.get<OptionsList>(
      `${server}/flow/getListOptions/${listId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      throw Error("Status was: " + response.status);
    }
  } catch (e) {
    throw e;
  }
}

const sendMessageApi = async (
  mensaje: string,
  idUsuario?: string,
): Promise<ResponseWrapper<string>> => {
  const response = await axiosApiInstanceXeia.post(
    sendMessagePath,
    {
      idUsuario: idUsuario,
      mensaje: mensaje,
    }
  );
  if (response.status !== 200) {
    return {
      success: false,
      errorMessage: `Error inesperado: ${response.status}`,
      responseData: undefined,
    };
  } else {
    return {
      success: true,
      errorMessage: undefined,
      responseData: response.data,
    };
  }
};

//DTOS
export interface ResponseWrapper<Type> {
  responseData?: Type;
  success: boolean;
  errorMessage?: string;
}

export interface GetFromCodeResponse {
  token: string;
  filledForm: FilledForm;
  drawableFormTemplate: DrawableFormTemplate;
  errorMessage?: string;
  optionsLists: { [key: string]: OptionsList };
  existanceConditionalConfigs: { [key: string]: ExistanceConditionalConfig };
  redirectionUrl?: string;
}

export interface GenericStatus {
  success: boolean;
  errorMessage?: string;
}

export interface BrandConfig {
  backgroundColor1: string;
  backgroundColor2: string;
  logoUrl: string;
  darkMode: boolean;
}

export interface UploadFileRequest {
  fileBase64: string;
  fileName: string;
  stitchTypeId: string;
}

export interface RedirectionUrlResponse {
  success: boolean;
  url?: string;
  errorMessage?: string;
}

export interface ExistanceConditionalConfig {
  id: string;
  values: Array<string>;
}

export interface PauseFormResponse {
  sentEmail: boolean;
  error?: string;
}

export interface PauseVerifyRequest {
  code: string;
  pauseCode: string;
}

export interface PauseVerifyResponse {
  genPath: string;
  error?: string;
}

export {
  sendMessageApi
}
import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./SFinalAfiliacion.scss";

function SFinalAfiliacion() {
  const history = useHistory();

  const NextRed = () => {
    history.push("./HQueDeseaHacer" + history.location.search);
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title1" style={{ fontWeight: "bold" }}>
          ¡ Excelente !
        </p>
        <p className="title2" style={{ fontWeight: "bold", marginBottom: 0 }}>
          HA SIDO AFILIADO
        </p>

        <p className="title3" style={{ fontWeight: "bold", marginTop: 0 }}>
          de manera exitosa
        </p>
      </div>

      <div className="btn-enter">
        <ButtonStyle
          text="Regresar al MENÚ principal"
          onClick={() => NextRed()}
          width="177px"
        />
      </div>
    </div>
  );
}
export default SFinalAfiliacion;

import React, { useState } from "react";
import "./UIngresaCredito.scss";
import {
  TextFieldStyleSS,
  TextFieldStyleSSelect,
} from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";

function UIngresaCredito(props) {
  const history = useHistory();
  const [data, setData] = useState("Personal");
  const [data2, setData2] = useState("Gobierno");
  const [data3, setData3] = useState("1000000");
  const Next = () => {
    props.getData();

    history.push("./VAdjunta" + history.location.search);
  };

  const userChange = (name, value) => {
    let temp = props.data;
    temp[name] = value;
    props.setData(temp);
  };

  return (
    <div className="enterdata-style">
      <p>
        Seleccione el tipo <br /> de crédito del que <br /> desea disponer
      </p>
      <br />
      <br />
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSSelect
          data={
            <FormControl variant="outlined">
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "white" }}
                shrink
              >
                Tipo de Crédito
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data}
                style={{ color: "white", borderColor: "white", width: "80vw" }}
                onChange={(e) => {
                  setData(e.target.value);
                  userChange("tipocredito", e.target.value);
                }}
                input={<OutlinedInput notched label={"Tipo de Crédito"} />}
              >
                <MenuItem value={"Personal"}>Personal</MenuItem>
                <MenuItem value={"Automovil"}>Automovil</MenuItem>
              </Select>
            </FormControl>
          }
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSSelect
          data={
            <FormControl variant="outlined">
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "white" }}
                shrink
              >
                Sector laboral
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data2}
                style={{ color: "white", borderColor: "white", width: "80vw" }}
                onChange={(e) => {
                  setData2(e.target.value);
                  userChange("sector", e.target.value);
                }}
                input={<OutlinedInput notched label={"Sector laboral"} />}
              >
                <MenuItem value={"Gobierno"}>Gobierno</MenuItem>
                <MenuItem value={"Privado"}>Privado</MenuItem>
                <MenuItem value={"Independiente"}>Independiente</MenuItem>
              </Select>
            </FormControl>
          }
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSSelect
          data={
            <FormControl variant="outlined">
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "white" }}
                shrink
              >
                Monto a solicitar
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data3}
                style={{ color: "white", borderColor: "white", width: "80vw" }}
                onChange={(e) => {
                  setData3(e.target.value);
                  userChange("monto", e.target.value);
                }}
                input={<OutlinedInput notched label={"Monto a solicitar"} />}
              >
                <MenuItem value={"1000000"}>$1.000.000</MenuItem>
                <MenuItem value={"3000000"}>$3.000.000</MenuItem>
                <MenuItem value={"5000000"}>$5.000.000</MenuItem>
                <MenuItem value={"7000000"}>$7.000.000</MenuItem>
              </Select>
            </FormControl>
          }
        />
      </div>

      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Salario actual"
          type="number"
          onChange={(e) => {
            userChange("salario", e.target.value);
          }}
        />
      </div>
      <div className="btn-enter">
        <ButtonStyle
          text="CONTINUAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default UIngresaCredito;

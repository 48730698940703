import React, { useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle, ButtonStyleWithWidth } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./HQueDeseaHacer.scss";

function HQueDeseaHacer() {
  const history = useHistory();

  const NextOptions1 = () => {
    history.push("./IVamosCuentaAhorros" + history.location.search);
  };
  const NextOptions2 = () => {
    history.push("./NVamosSeguro" + history.location.search);
  };
  const NextOptions3 = () => {
    history.push("./OVamosAfiliacion" + history.location.search);
  };
  const NextOptions4 = () => {
    history.push("./TVamosCredito" + history.location.search);
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "20vh" }}
      >
        <p className="title2">
          ¿Que deseas <br /> hacer el día de hoy?
        </p>
      </div>

      <div style={{ marginTop: "3em" }}>
        <ButtonStyleWithWidth
          text="Apertura cuenta de ahorros"
          onClick={() => NextOptions1()}
          width="80vw"
          height={"3em"}
        />
      </div>
      <div style={{ marginTop: "2em" }}>
        <ButtonStyleWithWidth
          text="Comprar un Seguro"
          onClick={() => NextOptions2()}
          width="80vw"
          height={"3em"}
        />
      </div>
      <div style={{ marginTop: "2em" }}>
        <ButtonStyleWithWidth
          text="Afiliarme"
          onClick={() => NextOptions3()}
          width="80vw"
          height={"3em"}
        />
      </div>
      <div style={{ marginTop: "2em" }}>
        <ButtonStyleWithWidth
          text="Solicitud de crédito"
          onClick={() => NextOptions4()}
          width="80vw"
          height={"3em"}
        />
      </div>
    </div>
  );
}
export default HQueDeseaHacer;

import React, { useEffect, useRef, useState } from "react";
import logo from "./../../assets/logoins.png";
import { ButtonStyle } from "../../utils/Buttons/Buttons";
import Radio from "@mui/material/Radio";
import { useHistory } from "react-router-dom";

import "./GHola.scss";

function GHola(props) {
  const history = useHistory();
  const timer = useRef();
  const [name, setName] = useState("");
  useEffect(() => {
    if (name === "") {
      setName(props.getName(setName));
    }
    timer.current = setInterval(() => {
      NextOptions();
    }, 5000);
  }, []);

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );
  const NextOptions = () => {
    history.push("./hQueDeseaHacer" + history.location.search);
  };
  return (
    <div className="screen1">
      <div
        className="box_one"
        style={{ marginBottom: "2em", marginTop: "30vh" }}
      >
        <p
          className="title2"
          style={{
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          ¡ Hola !
        </p>

        <p className="title2" style={{ fontWeight: "bold" }}>
          Bienvenid@ a tu <br /> agencia Virtual
        </p>
      </div>
    </div>
  );
}
export default GHola;

import React, { useState } from "react";
import "./RVerifiquemosAfiliacion.scss";
import { TextFieldStyleSS } from "../../utils/TextFieldStyle/TextFieldStyle";
import { Button, ButtonStyle } from "../../utils/Buttons/Buttons";
import { useHistory } from "react-router-dom";

function RVerifiquemosAfiliacion(props) {
  const history = useHistory();

  const Next = () => {
    props.redirect();
  };

  const userChange = (name, value) => {
    let temp = props.data;
    temp[name] = value;
    props.setData(temp);
  };

  return (
    <div className="enterdata-style">
      <p>Verifiquemos tus datos</p>

      <div style={{ marginTop: "3em" }}>
        <TextFieldStyleSS
          label="Nombre y Apellido"
          value={props.data["nombre"]}
          onChange={(e) => {
            userChange("nombre", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Dirección"
          value={props.data["direccion"]}
          onChange={(e) => {
            userChange("direccion", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Correo"
          value={props.data["correo"]}
          onChange={(e) => {
            userChange("correo", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Estado civil"
          value={props.data["estado"]}
          onChange={(e) => {
            userChange("estado", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Actividad económica"
          value={props.data["actividad"]}
          onChange={(e) => {
            userChange("actividad", e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "5em" }}>
        <TextFieldStyleSS
          label="Ingresos mensuales"
          value={props.data["ingresos"]}
          onChange={(e) => {
            userChange("ingresos", e.target.value);
          }}
        />
      </div>

      <div className="btn-enter">
        <ButtonStyle
          text="ENVIAR Y FIRMAR"
          onClick={() => Next()}
          width="177px"
          height="5vh"
        />
      </div>
    </div>
  );
}
export default RVerifiquemosAfiliacion;

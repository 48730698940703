import { useEffect, useState } from "react";
import useQuery from "../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import { ButtonStyle } from "../utils/Buttons/Buttons";
import { infoMessage } from "../api/errorHandler";
import { ConstructionOutlined } from "@mui/icons-material";
import { TextFieldStyleDummies } from "../utils/TextFieldStyle/TextFieldStyle";
import {
  coreIdCallback,
  canContinue,
  getColorsByCodeId,
  getColorsByExecFlow,
} from "./hyperFlowApi";
import { useHistory } from "react-router-dom";
import backgroundImage from "../assets/backgrounds/007-SKY.jpg";

const HyperFlowContinue = () => {
  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  let history = useHistory();
  //query params
  const query = useQuery();
  const code = query.get("code") ?? "error";
  //State selectors

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [darkMode, setDarkMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [url, setUrl] = useState("");
  const [continueMessage, setContinueMessage] = useState(
    "Continuemos con tu proceso"
  );

  //get colors
  async function getColors() {
    let resp = await getColorsByCodeId(code);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setDarkMode(resp.darkMode);
  }

  async function canContinueF() {
    setLoading(true);
    let resp = await canContinue(code);
    console.log(resp);
    let userAction = "WAIT";
    if (resp) {
      setStatus(resp.userAction);
      userAction = resp.userAction;
      if (resp.url) {
        setUrl(resp.url);
      }
    }
    setLoading(false);
    let keepAsking = false;
    if (userAction === "WAIT" || userAction === "DONT_WAIT") {
      keepAsking = true;
    } else {
      if (resp?.autoAdvance === true && userAction === "CONTINUE") {
        console.log(resp?.url ?? "");
        window.location.href = resp?.url ?? "";
        return;
      }
      if (resp?.message !== null && resp?.message !== "") {
        setContinueMessage(resp?.message ?? "Continuemos con tu proceso");
      }
      keepAsking = false;
    }
    while (keepAsking) {
      await delay(5000);
      console.log("keep asking");
      let resp = await canContinue(code);
      console.log(resp);
      if (resp) {
        setStatus(resp.userAction);
        userAction = resp.userAction;
        if (resp.url) {
          setUrl(resp.url);
        }
      }
      if (userAction === "WAIT" || userAction === "DONT_WAIT") {
        keepAsking = true;
      } else {
        if (resp?.autoAdvance === true && userAction === "CONTINUE") {
          console.log(resp?.url ?? "");
          window.location.href = resp?.url ?? "";
          return;
        }
        if (resp?.message !== null && resp?.message !== "") {
          setContinueMessage(resp?.message ?? "Continuemos con tu proceso");
        }
        keepAsking = false;
      }
    }
  }

  async function redirect() {
    window.location.href = url;
  }

  useEffect(() => {
    getColors();
    canContinueF();
  }, []);

  const customStyle = {
    "--fontColorPrimary": darkMode ? "#ffffff" : "#000000",
  } as React.CSSProperties;
  //Render
  return (
    <div className="hfMaster" style={customStyle}>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${backgroundImage})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          {loading && (
            <div>
              <h1
                className="txt-label-title-hf"
                style={{ fontSize: "", marginBottom: "50px" }}
              >
                Espera un momento
              </h1>
              <ClipLoader
                loading={loading}
                size={150}
                color={darkMode ? "#FFFFFF" : "#000000"}
              />
            </div>
          )}

          {!loading && (
            <div>
              {status === "WAIT" && (
                <div>
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  >
                    Espera un momento, estamos procesando tu información
                  </h1>
                  <ClipLoader loading={true} size={150} color={"#FFFFFF"} />
                </div>
              )}
              {status === "DONE" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  >
                    ¡Tu proceso ha terminado!
                  </h1>
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  ></h1>
                  <ButtonStyle
                    onClick={() => {
                      window.location.href = url;
                    }}
                    text="Terminar"
                  />
                </div>
              )}
              {status === "CONTINUE" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  >
                    {continueMessage}
                  </h1>
                  <ButtonStyle
                    onClick={() => {
                      window.location.href = url;
                    }}
                    text="Continuar"
                  />
                </div>
              )}
              {status === "DONT_WAIT" && (
                <div>
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  >
                    Tu proceso está en revisión. Te enviaremos un correo para
                    continuar.
                  </h1>
                  <h1
                    className="txt-label-title-hf"
                    style={{ fontSize: "", marginBottom: "50px" }}
                  >
                    Puedes cerrar esta ventana
                  </h1>
                  <ClipLoader loading={true} size={150} color={"#FFFFFF"} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HyperFlowContinue;

import { React } from "react";
import axios from "axios";
import * as rax from "retry-axios";
import { commonError, fatalError, infoMessage } from "./errorHandler";
import DummiesApi from "./dummies_api";
import WelcomeFormScreen2 from ".././Dummies/InitialForm/WelcomeFormScreen2";
import InitialForm2 from ".././Dummies/InitialForm/InitialForm2";
import FinalForm2 from ".././Dummies/InitialForm/FinalForm2";

const server = process.env.REACT_APP_BACK_URL;

rax.attach();
export default class Api {
  constructor() {
    this.token = "";
  }

  replaceDomain(url, newDomain) {
    const urlObj = new URL(url); // parse the url into an URL object
    urlObj.hostname = newDomain; // replace the hostname property with the new domain
    return urlObj.toString(); // convert back to a string and return
  }
  get_server() {
    if (server.endsWith("/")) {
      return server.slice(0, -1);
    }
    return server;
  }
  //Create user -  Ingresa los siguientes datos
  async create_user(number, placa, code) {
    var url = this.get_server() + "/registeruser";

    var req = {
      number_identification: parseInt(number),
      type_identification: "CC",
      natural_person: true,
      id_document: 1,
      placa: placa,
      code: code,
    };

    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }

  async sendVideo(number, video) {
    var url = this.get_server() + "/registeruserimpvideo";

    var req = {
      number_identification: parseInt(number),
      video: video,
    };

    axios({
      method: "post",
      url: url,
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
        infoMessage(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }

  async sendImage(data, method) {
    var url = this.get_server() + "/sendimage";

    axios
      .post(url, data, {
        headers: {
          accept: "application/json",
          "Content-Type": `multipart/form-data`,
        },
      })
      .then((response) => {
        console.log("GetInfo");
        infoMessage("Contrato recibido correctamente.");
        method();
      })
      .catch((e) => commonError(e.toString()));
  }

  //Get Info Vehiculos
  getInfoVehiculos(ni, nd) {
    var url =
      this.get_server() +
      "/records?" +
      "number_identification=" +
      ni +
      "&number_document=" +
      nd;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetInfo");
        console.log(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }

  //Get Redirect MFA en requirement - requisitos previos
  getRedirectMFA(ni) {
    var url =
      this.get_server() + "/geturluser?" + "number_identification=" + ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        window.location = response.data.url;
      })
      .catch((e) => commonError(e.toString()));
  }

  getRedirectMFAINS(req) {
    let url = this.get_server() + "/registeruserins";
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),

      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        window.location = response.data.url;
      })
      .catch((e) => commonError(e.toString()));
  }

  getInfoINS(req, method, user) {
    let url = this.get_server() + "/getusermultifactorins";
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),

      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        method(response.data);

        this.sendEmailINS(user);
      })
      .catch((e) => commonError(e.toString()));
  }

  sendEmailINS(ni) {
    var url = this.get_server() + "/sendEmailGeneric?" + "user=" + ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }
  //Update registration - email - registrationId /alerta codigo incorrecto
  async create_registration(reg) {
    var url = this.get_server() + "/registerusermfa";
    var req = {
      registrationId: reg,
      name: "Laura Isabella Forero Camacho",
    };
    var response = await axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + this.token,
      },
    });
    return response.data;
  }

  //Boton de reenviar correo improntas
  async sendemail_improntas(ni) {
    var url =
      this.get_server() +
      "/sendEmailImprontas?" +
      "number_identification=" +
      ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("SendEmail");
        console.log(response.data);
        infoMessage("Correo enviado con exito. Revisa tu correo electrónico.");
      })
      .catch((e) => commonError(e.toString()));
  }

  //Boton continuar en verify
  async sendemail_pago(ni) {
    var url =
      this.get_server() + "/sendEmailPago?" + "number_identification=" + ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("SendEmail");
        console.log(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }

  //Boton pse - pay
  async get_url_pay(ni) {
    return "";
  }

  //Send email final - vista final siguiente- Alerta : Soportes enviados correctamente a tu correo.
  sendEmailFinal(ni) {
    var url = this.get_server() + "/sendEmail?" + "number_identification=" + ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("SendEmail");
        console.log(response.data);
      })
      .catch((e) => commonError(e.toString()));
  }

  ///Coocique
  getRedirectMFACOO(req) {
    var url = this.get_server() + "/registerusercoocique";
    return axios({
      method: "post",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      data: JSON.stringify(req),

      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        // window.location = response.data.url;
        var dummies = new DummiesApi();

        dummies
          .getCoreIdLinkGeneric({
            redirectionUrl: window.location.href.replace(
              window.location.pathname,
              "/coociquefinal"
            ),
            formId: "61a1026365b72dc69e979e22",
            params: {
              user: response.data.id,
            },
          })
          .then((d) => {
            console.log(d);

            window.location = d.url;
          });
      })
      .catch((e) => commonError(e.toString()));
  }

  sendEmailCOO(ni, reg) {
    var url =
      this.get_server() +
      "/redirectFirmaCoocique?" +
      "user=" +
      ni +
      "&registrationId=" +
      reg;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        window.location = response.data.url;
      })
      .catch((e) => commonError(e.toString()));
  }

  ///Comercial
  getRedirectMFACOM(code) {
    let dummies = new DummiesApi();

    dummies
      .getCoreIdLinkGeneric({
        redirectionUrl: window.location.href
          .replace(window.location.pathname, "/EFinalizarIdentidad")
          .split("?")[0],
        formId:
          code !== "jaimetest"
            ? "61f8288a842b15fd6e034355"
            : "61fa8d209ffb931f0b6955b6",
        params: {
          code: code,
        },
      })
      .then((d) => {
        console.log(d);

        window.location = d.url;
      });
  }

  getRedirectMFAACOM(ni) {
    let url = this.get_server() + "/getRegId?" + "user=" + ni;

    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log(response.data);
        let dummies = new DummiesApi();
        let tempUrl = window.location.href.replace(
          window.location.pathname,
          "/GHola"
        );
        console.log(window.location.pathname);
        console.log(window.location.href);
        tempUrl = tempUrl.split("?")[0];
        let meta = {
          code: ni,
        };
        dummies
          .getAuthRedirectGeneral(response.data.regId, tempUrl, meta)
          .then((d) => {
            console.log(d);
            window.location = d.url;
          });
      })
      .catch((e) => commonError("Código incorrecto"));
  }

  sendEmailCOM(ni, doc) {
    let url =
      this.get_server() +
      "/redirectFirmaComercial?" +
      "user=" +
      ni +
      "&file=" +
      doc;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log("GetUrl");
        console.log(response.data);
        window.location = response.data.url;
      })
      .catch((e) => commonError(e.toString()));
  }

  getusercomercial(ni, data, activity, final) {
    let url = this.get_server() + "/getusercomercial?" + "user=" + ni;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        final(response["data"], data, activity);
      })
      .catch((e) => commonError(e.toString()));
  }

  getRedirectMagic(ni, pro, regId) {
    let url =
      this.get_server() +
      "/getURLMagic?" +
      "user=" +
      ni +
      "&process=" +
      pro +
      "&regId=" +
      regId;
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((d) => {
        console.log(d.data);
        window.location = this.replaceDomain(d.data.url, "new.forms.anteia.co");
      })

      .catch((e) => commonError("Código incorrecto"));
  }

  getName(ni, setName) {
    let url = this.get_server() + "/getName?" + "user=" + ni;
    console.log(url);
    return axios({
      method: "get",
      url: url,
      raxConfig: {
        retry: 3, // number of retry when facing 4xx or 5xx
        noResponseRetries: 3, // number of retry when facing connection error
        onRetryAttempt: (err) => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg.currentRetryAttempt}`); // track current trial
        },
      },
      headers: {
        "Content-Type": "application/json",
        //  Authorization: "Bearer " + this.token,
      },
    })
      .then((response) => {
        console.log(response.data);
        setName(response.data.name);
      })
      .catch((e) => commonError("Código incorrecto"));
  }
}
